import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";

import { sleep, startLoading, endLoading } from "@/utils/function"; 
import API from '@/api/teacher/center/webNoticeUpdate';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "other",
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            // ===========================  ueditor  使用代码 start   ================
            defaultMsg: "", //默认显示的文字
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: 1200,
                // 初始容器高度
                initialFrameHeight: 350,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                        "inserttable",//表格
                        "source", //源代码
                        "bold", //加粗
                        "italic", //斜体
                        "underline", //下划线
                        "strikethrough", //删除线
                        "fontborder", //字符边框
                        "blockquote", //引用
                        "selectall", //全选
                        "horizontal", //分隔线
                        "removeformat", //清除格式
                        "unlink", //取消链接
                        "deletecaption", //删除表格标题
                        "inserttitle", //插入标题
                        "cleardoc", //清空文档
                        "insertcode", //代码语言
                        "fontfamily", //字体
                        "fontsize", //字号
                        "insertimage", //多图上传
                        "link", //超链接
                        "emotion", //表情
                        "spechars", //特殊字符
                        "searchreplace", //查询替换
                        "insertvideo", //视频
                        "justifyleft", //居左对齐
                        "justifyright", //居右对齐
                        "justifycenter", //居中对齐
                        "forecolor", //字体颜色
                        "insertorderedlist", //有序列表
                        "insertunorderedlist", //无序列表
                        "imageleft", //左浮动
                        "imageright", //右浮动
                        "attachment", //附件
                        "imagecenter", //居中
                        "lineheight", //行间距
                        "scrawl" //涂鸦
                    ]
                ]
            },
            ue1: "ue1", // 不同编辑器必须不同的id
            ueState: false, //ue状态
            // ===========================  ueditor  使用代码 end      ================
            // 项目id
            article_id: "",
            // 文章标题
            title: "",
            // 是否公开
            radio: "0",
            // 用户id
            userid: "",
            // 图片链接
            logo: "",
            // 发布日期
            datePicker: new Date(),
        };
    },
    created() {
        const self = this;
        self.input = this.$router.currentRoute.query.search;
        self.article_id = Number(this.$router.currentRoute.query.article_id);
        self.getData();
    },
    methods: {
        /**
         * @name: 上传图片成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        handleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) 
            {
                self.logo= res.data;
                // Message.success(res.msg);
                this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            // else 
            // {
            //     Message.error(res.msg);
            // }
        },
        /**
         * @name: 上传图片前校验
         * @author: camellia
         * @date: 2021-03-01
         * @param:	file	json	文件流
         */
        beforeAvatarUpload(file) 
        {
            const isJPG =
                file.type === "image/jpeg" ||
                "image/png" ||
                "image/jpg" ||
                "image/gif" ||
                "image/bmp";
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) 
            {
                // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
                this.$Tips({
                    // 消息提示内容
                    message:"上传头像图片只能是 JPG/png/gif/bmp 格式",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
            }
            if (!isLt2M) 
            {
                // Message.error("上传头像图片大小不能超过 5MB!");
                this.$Tips({
                    // 消息提示内容
                    message:"上传头像图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
            }
            return isJPG && isLt2M;
        },
        /**
         * @name: 获取editor示例
         * @author: camellia
         * @date: 2021-01-18 10:13:50
         */
        ready(editorInstance) {
            var self = this;
            // console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
            var str = "center_notice_" + self.article_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        /**
         * @name: 返回上一级
         * @author: camellia
         * @date: 2021-01-15 14:00:30
         */
        goBack() {
            this.$router.go(-1);
        },
        /**
         * @name: 修改文章
         * @author: camellia
         * @date: 2021-01-15 14:34:02
         */
        execUpdateArticle() 
        {
            const self = this;
            if (!self.title) 
            {
                // Message.warning("请填写公告标题！");
                this.$Tips({
                    // 消息提示内容
                    message:"请填写公告标题！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            } 
            else if (!self.defaultMsg) 
            {
                // Message.warning("请填写公告内容！");
                this.$Tips({
                    // 消息提示内容
                    message:"请填写公告内容！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    button:[
                        {
                            type:1,
                            text:'知道了'
                        }
                    ]
                })
                return;
            } 
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            let data = {
                content: self.defaultMsg,
                title: self.title,
                logo: self.logo,
                datePicker: self.datePicker,
                id: self.article_id                
            }
            API.updateNotice(data)
                .then(result => {
                    nprogressClose();
                    self.$Loading.hide();
                    this.$Tips({
                        // 消息提示内容
                        message:result.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'success',
                        displayTime:1500
                    })
                })
                .catch(err => {});
        },
        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() 
        {
            const self = this;
            let data = {
                article_id: self.article_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getNoticeInfo(data)
                .then(result => {
                    self.userid = result.userid;
                    self.title = result.data.title;
                    self.defaultMsg = result.data.content;
                    self.logo = result.data.logo;
                    self.datePicker = result.data.addtime;
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(err => {});
        }
    }
};
