import { request } from "@/request/http";

export default {
  getNoticeInfo: (data) => {
    return request("GET", `/index.php/center/getNoticeInfo`, data);
  },

  updateNotice: (data) => {
    return request("POST", `/index.php/center/updateNotice`, data);
  },
}
